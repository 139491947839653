
import {
  Button,
  Layout,
  Modal,
  Popconfirm,
  Progress,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import ModelEditor, { ApsModel } from '../../components/connected/ModelEditor';
import React, { FC, useEffect, useState } from 'react';
import { blue, red } from '@ant-design/colors';
import { cloneDeep, isEmpty, keyBy } from 'lodash';
import { fetchBlueprint, update as updateBlueprint } from '../../services/configuration';
import {
  getAssetTypes,
  getAssetTypesById,
  getProjectId,
  getScratchProjectCode,
  getTenant,
} from '../../selectors';
import { getAssets, getLayers } from '../../actions';
import { Blueprint } from '@ynomia/core/dist/project/blueprint';
import ModelSetupModal from '../../components/connected/ModelSetupModal';
import { PageProps } from '../../config/types';
import client from '../../services/Client';
import { formatDate } from '../../utils';
import { getContextStores } from '../../context';
import { modelIsEnabled } from '../../utils/modelViewing';
import styles from './styles.module.less';

const { Content } = Layout;

const Models: FC<PageProps> = () => {
  const contextStores = getContextStores();
  const {
    assetDispatch,
    assetState,
    layerDispatch,
    layerState,
  } = contextStores;
  // Upload Modal States
  const [ isSetupModalOpen, setIsSetupModalOpen ] = useState<boolean>(false);
  const [ isEditModalOpen, setIsEditModalOpen ] = useState<boolean>(false);

  // Models
  const [ fetchingModels, setFetchingModels ] = useState<boolean>(true);
  const [ firstFetch, setFirstFetch ] = useState<boolean>(true);
  const [ apsModels, setApsModels ] = useState<Array<ApsModel>>([]);
  const [ bpModels, setBpModels ] = useState<Blueprint['models']>();
  const [ togglingModels, setTogglingModels ] = useState<{ [objectKey: string]: boolean }>({});
  const [ savingInProgress, setSavingInProgress ] = useState<boolean>(false);
  const modelsKeyedById = bpModels?.forge.settings.individualModels || {};
  type ValueOf<T> = T[keyof T];
  type IndividualModel = ValueOf<Blueprint['models']['forge']['settings']['individualModels']>;

  const [ stepOverride, setInitialStep ] = useState<number>(0);
  const [ focusedModel, setFocusedModel ] = useState<string | undefined>(undefined);

  /* Selectors */
  const projectCode = getScratchProjectCode(contextStores);
  const projectId = getProjectId(contextStores);
  const tenant = getTenant(contextStores);

  const assetTypes = getAssetTypes(contextStores);
  const assetTypesKeyedById = getAssetTypesById(contextStores);

  const { lastFetchStartTime } = assetState;

  const apsModelsKeyedByObjectKey = keyBy(apsModels, 'objectKey');

  const fetchModels = async () => {
    setFetchingModels(true);
    // Pull blueprint separately as model is usually hidden in the bootstrap
    const blueprint: Blueprint = await fetchBlueprint(tenant, projectCode);

    // Also keep the bootstrap updated
    await client.server.bootstrap(projectId);

    try {
      const listResp = await client.server.get(
        `/scratch/models/${tenant}/${projectCode}/list`,
      );
      setApsModels(listResp.data.value);
    } catch (e) {
      throw e;
    } finally {
      // Only update the UI once everything is done.
      setBpModels(blueprint.models);
      setFetchingModels(false);
      setFirstFetch(false);
    }
  };

  /**
   * We want the site to feel responsive.
   * `bpModels` takes time to load.
   * If not available yet, we fetch it.
   */
  const getOrFetchModels = async () => {
    let models = bpModels;
    if (!models) {
      const blueprint = await fetchBlueprint(tenant, projectCode);
      models = blueprint.models;
    }
    return models;
  };

  const updateModel = async (
    objectKey: string,
    body: IndividualModel | undefined,
    offset?: { x: number, y: number, z: number },
  ) => {
    setSavingInProgress(true);
    const models = await getOrFetchModels();
    const clone = cloneDeep(models);

    // Keep the twinID fields array updated
    if (clone?.forge?.settings) {
      const allTwinIds = Object.values(clone.forge.settings.individualModels)
        .map(m => m.twinIdField);
      const uniqueTwinIds = Array.from(new Set(allTwinIds));
      clone.forge.settings.fields = uniqueTwinIds;
    }

    if (offset) {
      clone.forge.settings.modelConfig = { offset };
    }

    // Update model
    if (body === undefined) {
      delete clone!.forge.settings.individualModels[objectKey];
    } else {
      clone!.forge.settings.individualModels[objectKey] = body;
    }
    await updateBlueprint(tenant, projectId, projectCode, { models: clone });
    await fetchModels();
    setSavingInProgress(false);
  };

  const updateOffset = async (offset: { x: number, y: number, z: number }) => {
    setSavingInProgress(true);
    const models = await getOrFetchModels();
    const clone = cloneDeep(models);

    clone.forge.settings.modelConfig = { offset };

    await updateBlueprint(tenant, projectId, projectCode, { models: clone });
    await fetchModels();
    setSavingInProgress(false);
  };

  /**
   * There are two locations in where we associate models with asset types.
   * The first is in the blueprint `blueprint.models.forge.settings.individualModels` which gives
   * us a list of all models enabled for the asset types.
   * The second is under the model `model.assetTypes` which tells us the intended asset type of the
   * model regardless if it  is enabled or not.
   * As the latter is a new field, we want to make sure that when this page is loaded,
   * it is kept up to date with the blueprint list.
   * @param blueprint Blueprint
   */
  const fixModelAssetTypes = async () => {
    const blueprint: Blueprint = await fetchBlueprint(tenant, projectCode);
    const assetTypeModelMapping: { [modelId: string]: Array<string> } = {};
    assetTypes.forEach(({ individualModelIds, type }) => {
      individualModelIds?.forEach((id) => {
        if (!assetTypeModelMapping[id]) assetTypeModelMapping[id] = [];
        assetTypeModelMapping[id].push(type);
      });
    });

    await Promise.all(Object.keys(blueprint.models.forge.settings.individualModels)
      .map((m) => {
        const model = blueprint.models.forge.settings.individualModels[m];
        const modelsArr = model.assetTypes || [];
        const assetTypesArr = assetTypeModelMapping[m] || [];
        const combinedArr = Array.from(new Set([...modelsArr, ...assetTypesArr]));
        if (modelsArr?.sort().join() === combinedArr.sort().join()) return;
        return updateModel(
          m,
          {
            ...model,
            assetTypes: Array.from(combinedArr),
          },
        );
      }));
  };

  useEffect(() => {
    // Need this to visualise twinID mapping
    getAssets(tenant, projectCode, assetTypes, lastFetchStartTime, assetDispatch);
    getLayers(tenant, projectCode, layerState.lastFetchStartTime, layerDispatch);
  }, []);

  useEffect(() => {
    fetchModels();
    fixModelAssetTypes();
    const t = setInterval(fetchModels, 60 * 1000);
    return () => clearInterval(t);
  }, []);

  const updateMap = async (
    objectKey: string,
    mapping: Record<string, Array<number>>,
    twinIdField: string,
    offset?: { x: number, y: number, z: number },
  ) => {
    if (!objectKey) return;
    await updateModel(
      objectKey,
      {
        ...modelsKeyedById[objectKey],
        mapping,
        twinIdField,
      },
      offset,
    );
  };

  const deleteObject = async (objectKey: string) => {
    await updateModel(objectKey, undefined);
    if (apsModelsKeyedByObjectKey[objectKey]) {
      await client.server.delete(
        `/scratch/models/${tenant}/${projectCode}/object/${objectKey}`,
      );
    }
    await fetchModels();
  };

  const generateStatus = (objectKey: string, m: ApsModel) => {
    if (!m) {
      return <div style={{ textAlign: 'center' }}>Not on APS</div>;
    }

    const getModelStatus = (): {
      step: number,
      fillColor: string,
      tooltipMessage: string,
      icon: React.ReactNode
    } => {
      const failed = red[6];
      const normal = blue[6];
      if (!m) {
        return {
          step: 0,
          fillColor: normal,
          tooltipMessage: '',
          icon: <></>,
        };
      }

      if (!m.manifest || m.manifest.status === 'failed') {
        return {
          step: 2,
          fillColor: failed,
          tooltipMessage: 'Failed to translate',
          icon: <CloseCircleOutlined />,
        };
      }

      if (m.manifest?.progress !== 'complete') {
        return {
          step: 2,
          fillColor: normal,
          tooltipMessage: `Translating - ${m.manifest?.progress}`,
          icon: <LoadingOutlined />,
        };
      }

      if (isEmpty(modelsKeyedById[objectKey].mapping)) {
        return {
          tooltipMessage: 'Mapping Required.',
          fillColor: normal,
          step: 3,
          icon: <ExclamationCircleOutlined />,
        };
      }

      const mappedCount = Object.keys(modelsKeyedById[objectKey].mapping).length;
      if (!modelIsEnabled(objectKey, modelsKeyedById, assetTypesKeyedById)) {
        return {
          tooltipMessage: `Enable the model to publish (${mappedCount})`,
          fillColor: normal,
          step: 4,
          icon: <CheckCircleOutlined />,
        };
      }

      return {
        tooltipMessage: `Published (${mappedCount})`,
        fillColor: normal,
        step: 4,
        icon: <CheckCircleOutlined />,
      };
    };

    const totalSteps = 5;
    const { step, fillColor, tooltipMessage, icon } = getModelStatus();
    const strokeColor = new Array(totalSteps).fill(fillColor);
    return (
      <div style={{ textAlign: 'center' }}>
        <Tooltip title={tooltipMessage} style={{ alignSelf: 'center' }}>
          <div
            onClick={() => {
              setFocusedModel(objectKey);
              setInitialStep(step);
              setIsSetupModalOpen(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Progress
              steps={totalSteps}
              percent={(step + 1) / totalSteps * 100}
              strokeColor={strokeColor}
              format={() => icon}
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  const tableColumns = [
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Upload File',
      dataIndex: 'originalFileName',
      sorter: true,
    },
    {
      title: 'Asset Type(s)',
      dataIndex: 'assetTypes',
    },
    {
      title: 'TwinID Field',
      dataIndex: 'twinIdField',
      sorter: true,
    },
    {
      title: 'Upload Date',
      dataIndex: 'date',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      width: 140,
    },
  ];

  const setModelEnabled = async (objectKey: string, enable: boolean) => {
    setTogglingModels(s => ({ ...s, [objectKey]: true }));
    setSavingInProgress(true);
    const model = modelsKeyedById[objectKey];
    const blueprint: Blueprint = await fetchBlueprint(tenant, projectCode);
    const assetTypesClone = cloneDeep(blueprint.asset_types);
    assetTypesClone?.types
      .filter(assetType => model.assetTypes?.includes(assetType.type))
      .forEach((assetType) => {
        if (!assetType.individualModelIds) assetType.individualModelIds = [];
        if (enable) {
          assetType.individualModelIds.push(objectKey);
        } else {
          const index = assetType.individualModelIds.indexOf(objectKey);
          assetType.individualModelIds.splice(index, 1);
        }
      });
    await updateBlueprint(tenant, projectId, projectCode, { asset_types: assetTypesClone });
    await fetchModels();
    setSavingInProgress(false);
    setTogglingModels(s => ({ ...s, [objectKey]: false }));
  };

  const generateActions = (objectKey: string, disableDeleteButton?: boolean) => {
    return <Space size="middle" style={{ justifyContent: 'start', width: '100%' }}>
    <Typography.Link
      onClick={() => {
        setFocusedModel(objectKey);
        setIsEditModalOpen(true);
      }}
    >
      Edit
    </Typography.Link>
    <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteObject(objectKey)}>
      <Typography.Link
        disabled={disableDeleteButton}
        type="danger"
      >
        Delete
      </Typography.Link>
    </Popconfirm>
  </Space>;
  };

  const dataSource = Object.keys(modelsKeyedById).map((objectKey) => {
    const apsModel = apsModelsKeyedByObjectKey[objectKey];
    const model = modelsKeyedById[objectKey];
    const enabled = modelIsEnabled(objectKey, modelsKeyedById, assetTypesKeyedById);
    const disableToggle = savingInProgress
      || !model.urn
      || (apsModel && apsModel.manifest.progress !== 'complete');
    return ({
      key: objectKey,
      name: objectKey,
      enabled: <Switch
          disabled={disableToggle}
          checked={enabled}
          onClick={() => setModelEnabled(objectKey, !enabled)}
          loading={togglingModels[objectKey]}
        />,
      status: generateStatus(objectKey, apsModel),
      action: generateActions(objectKey, enabled || savingInProgress),
      assetTypes: model.assetTypes?.map(t =>
        assetTypesKeyedById.get(t)?.name?.toLowerCase())
        .join(', '),
      twinIdField: model.twinIdField,
      originalFileName: model.originalFileName,
      date: formatDate(model.date),
    });
  });

  return (
    <Content className={styles.container}>
      <div className={styles.header}>
        <Typography.Title level={3} style={{ flex: 1 }}>
          Current Models
        </Typography.Title>
        <Button
          type="primary"
          icon={<SyncOutlined />}
          style={{ marginTop: 24, marginRight: 10 }}
          size={'middle'}
          onClick={() => fetchModels()}
          loading={fetchingModels}
        />
        <Button
          type="primary"
          onClick={() => {
            // Open Modal
            setFocusedModel(undefined);
            setInitialStep(0);
            setIsSetupModalOpen(true);
          }}
          style={{ width: 100, marginTop: 24 }}
        >
          Add Model
        </Button>
      </div>
      <Table columns={tableColumns} dataSource={dataSource} loading={firstFetch}/>
      <ModelSetupModal
        objectKeyOverride={focusedModel}
        modelsKeyedById={modelsKeyedById}
        apsModels={apsModels}
        savingInProgress={savingInProgress}
        isSetupModalOpen={isSetupModalOpen}
        stepOverride={stepOverride}
        fetchModels={fetchModels}
        setIsSetupModalOpen={setIsSetupModalOpen}
        setModelEnabled={setModelEnabled}
        modelOffset={bpModels?.forge.settings.modelConfig?.offset}
        updateMap={updateMap}
        updateModel={updateModel}
        updateOffset={updateOffset}
      />
      <Modal
        title={`Edit — ${focusedModel}`}
        open={focusedModel !== undefined && isEditModalOpen}
        width={1000}
        footer={null}
        maskClosable={false}
        onCancel={() => setIsEditModalOpen(false)}
        destroyOnClose
      >
        <ModelEditor
          objectKey={focusedModel!}
          modelsKeyedById={modelsKeyedById}
          apsModel={apsModelsKeyedByObjectKey[focusedModel!]}
          disableUpdate={savingInProgress}
          modelOffset={bpModels?.forge.settings.modelConfig?.offset}
          updateMap={updateMap}
          updateModel={updateModel}
          updateOffset={updateOffset}
        />
      </Modal>
    </Content>
  );
};

export default Models;
