/* irrespective of the environment, the following configuration remains constant */
import {
  IconDefinition,
  faBook,
  faChartLine,
  faClipboardList,
  faCubes,
  faFileCsv,
  faGear,
  faMap,
  faMoneyBillWave,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { DifferOptions } from 'json-diff-kit';

export type Category = {
  id: string,
  label: string,
};

export const categories: Array<Category> = [
  {
    id: 'activity',
    label: 'ACTIVITY',
  },
  {
    id: 'project setup',
    label: 'PROJECT SETUP',
  },
];

export const DEFAULT_PRETTY_MAC_ADDR_TRUNCATION = 4;

export type Route = {
  id: string,
  path: string,
  label: string,
  icon: IconDefinition,
};

export const PROJECT_SLUG = ':projectSlug';

export const routes: Array<Route> = [
  {
    id: 'status_tracking',
    path: `/${PROJECT_SLUG}/status-tracking`,
    label: 'Status Tracking',
    icon: faMap,
  },
  {
    id: 'user_management',
    path: `/${PROJECT_SLUG}/user-management`,
    label: 'User Management',
    icon: faUser,
  },
  {
    id: 'asset_update_tool',
    path: `/${PROJECT_SLUG}/asset-update-tool`,
    label: 'Asset Update Tool',
    icon: faFileCsv,
  },
  {
    id: 'status_update_tool',
    path: `/${PROJECT_SLUG}/status-update-tool`,
    label: 'Status Update Tool',
    icon: faFileCsv,
  },
  {
    id: 'models',
    path: `/${PROJECT_SLUG}/models`,
    label: 'Models',
    icon: faCubes,
  },
  {
    id: 'configuration',
    path: `/${PROJECT_SLUG}/configuration`,
    label: 'Configuration',
    icon: faGear,
  },
  {
    id: 'powerbi',
    path: `/${PROJECT_SLUG}/metrics-insights`,
    label: 'Metrics & Insights',
    icon: faChartLine,
  },
];

export const routeIcons = {
  faBook,
  faClipboardList,
  faCubes,
  faFileCsv,
  faGear,
  faMap,
  faMoneyBillWave,
  faUser,
};

export const routesKeyedById = new Map(routes.map(route=> [route.id, route]));

/**
 * @ynomia/client configuration constants.
 */
export const YNOMIA_CLIENT_SDK_NAME = 'webhqv2';
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const ID_TOKEN_STORAGE_KEY = 'id_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const PROJECT_ID_STORAGE_KEY = 'project_id';
export const REDIRECT_TO = 'redirect_to';
export const LOGIN_HINT = 'login_hint';

export const REGION = 'region';

/**
 * Periodic data fetching intervals.
 * 30 sec countdown, but +1s for visual effect.
 */
export const DEFAULT_FETCH_INTERVAL = 31000;
export const LAYER_AND_BOOTSTRAP_FETCH_INTERVAL = 60 * 60 * 1000;

export const DEFAULT_STATUS_COLOR = '#aaaaaa';

export type AssetUpdateMode = 'complete' | 'upsert' | 'append';
export type StatusUpdateMode = 'default';

export const IS_MOBILE = window.innerWidth < 768;

export const USER_INITIATED = 'user_initiated';
export const EXPIRED = 'expired';
export const USER_UNKNOWN = 'user_unknown';

export const BLUEPRINT_SLICE_NAMES = [
  'asset_types',
  'calendar',
  'features_mobile',
  'features',
  'integrations',
  'layer_templates',
  'layer_types',
  'metadata',
  'models',
  'network_request_mocks',
  'project_flags_mobile',
  'reports_v2',
  'role_types',
  'site_templates',
  'sites',
] as Array<string>;

export const differOptions: DifferOptions = {
  detectCircular: true,
  maxDepth: Infinity,
  showModifications: true,
  arrayDiffMethod: 'lcs',
};

export const PRESET_TWIN_ID_NAME = ['twinID', 'Ynomia Twin ID', 'Model Location Reference (MLR)'];

export const STATUS_TRACKING_PAGE_FEATURES = {
  'digitalTwin': true,
  'dateRange': true,
  'assetStatusSummary': true,
  'observationSummary': true,
  'layerSummary': true,
  'supplierSummary': true,
  'timeTravel': true,
  'layoutSwitcher': true,
  'assetDetails': true,
  'assetActions': true,
  'createAsset': true,
  'exportReports': true,
  'assetSelection': true,
};

export const DEFAULT_ASSET_PAGE_FEATURES = {
  'digitalTwin': false,
  'dateRange': false,
  'assetStatusSummary': false,
  'observationSummary': true,
  'layerSummary': false,
  'supplierSummary': false,
  'timeTravel': false,
  'layoutSwitcher': true,
  'assetDetails': false,
  'assetActions': false,
  'createAsset': false,
  'exportReports': false,
  'assetSelection': false,
};

export const CUSTOM_DATE_COLUMNS = ['formatDate', 'observationTypeDate'];
